import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const ContactSection = () => {
  return (
		<>
		<div className="flex flex-col md:flex-row justify-between mb-10 p-8 rounded-lg">
			<div className="md:w-1/2 flex flex-col justify-center">
				<h2 className="text-4xl font-semibold mb-6 text-gray-800">Контакты</h2>
				<ul className="space-y-4 text-lg text-gray-700">
					<li>
						<span className="font-bold">Телефон:</span> +7 (963) 178 66 71
					</li>
					<li>
						<span className="font-bold">Почта:</span> <a href="mailto:toma.ts@mail.ru" className="text-blue-500 hover:underline">toma.ts@mail.ru</a>
					</li>
					<li>
						<span className="font-bold">Адрес:</span> Республика Северная Осетия — Алания, Владикавказ, переулок Станиславского, 5. Здание Проектного института, 4 этаж, офис №406.
					</li>
				</ul>
				<div className="mt-8">
					<img src="/assets/portfolio/photo.jpg" alt="Contact Photo" className="w-32 h-44 object-cover rounded-lg shadow-md" />
				</div>
			</div>
			<div className="md:w-[70%] mt-10 md:mt-0 relative">
				<iframe
					title="map"
					src="https://yandex.ru/map-widget/v1/?um=constructor%3Af17f838c5f0323ad126a8be9338db8095a049f9b8464e8734915f305dac36932&amp;source=constructor"
					height="450"
					className="w-full rounded-lg shadow-md"
				></iframe>
			</div>
		</div>
		<Footer/>
		</>
  );
};



const Footer = () => {
  return (
    <footer className="flex justify-between items-center py-4 px-8 text-black mt-8 rounded-lg">
      <div className="flex space-x-4">
        <a href="https://www.instagram.com/tsogoevatamara?utm_source=qr&igsh=aTV6c3AwcThnNGw0" className="text-black">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://www.avito.ru/brands/e021223f24f4fec319a025440a2b1442" className="text-black">
					<img src='/assets/avito.jpg' alt="Avito" width={38} height={38}/>
        </a>
        <a href=" https://wa.me/79631786671" className="text-black">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
      </div>
      <div>
        <a href="http://spacity.vladikavkaz.ru" className="text-black text-lg">© Spacity</a>
      </div>
    </footer>
  );
};



export default ContactSection;