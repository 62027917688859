const Doma = [
	{
		id: 1,
		title: "Гостиничный и жилой дом. Цей, РСО-Алания. 2023",
		image: "/assets/portfolio/doma/gost_c/1.jpg",
		tag: ["Частные дома"],
		images: [
			"/assets/portfolio/doma/gost_c/1.jpg",
			"/assets/portfolio/doma/gost_c/2.jpg",
			"/assets/portfolio/doma/gost_c/2.1.jpg",
			"/assets/portfolio/doma/gost_c/3.jpg",
			"/assets/portfolio/doma/gost_c/4.jpg",
			"/assets/portfolio/doma/gost_c/5.jpg",
			"/assets/portfolio/doma/gost_c/5.1.jpg",
			"/assets/portfolio/doma/gost_c/6.jpg"
		]
	},
	{
		id: 2,
		title: "Частный дом в СНО Дарьял. Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/doma/sno_darial/1.jpg",
		tag: ["Последние проекты", "Частные дома"],
		images: [
			"/assets/portfolio/doma/sno_darial/1.jpg",
			"/assets/portfolio/doma/sno_darial/2.jpg",
			"/assets/portfolio/doma/sno_darial/3.jpg",
			"/assets/portfolio/doma/sno_darial/4.jpg",
			"/assets/portfolio/doma/sno_darial/5.jpg",
			"/assets/portfolio/doma/sno_darial/6.jpg",
			"/assets/portfolio/doma/sno_darial/7.jpg",
			"/assets/portfolio/doma/sno_darial/8.jpg",
			"/assets/portfolio/doma/sno_darial/9.jpg"
		]
	},
	{
		id: 3,
		title: "Частный дом в СНО Терек. Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/doma/sno_terek/1.jpg",
		tag: ["Частные дома"],
		images: [
			"/assets/portfolio/doma/sno_terek/1.jpg",
			"/assets/portfolio/doma/sno_terek/2.jpg",
			"/assets/portfolio/doma/sno_terek/3.jpg",
			"/assets/portfolio/doma/sno_terek/4.jpg",
			"/assets/portfolio/doma/sno_terek/5.jpg"
		]
	},
	{
		id: 4,
		title: "Частный дом в СТ Дружба. Владикавказ, РСО-Алания. 2021",
		image: "/assets/portfolio/doma/st_druzba/1.jpg",
		tag: ["Частные дома"],
		images: [
			"/assets/portfolio/doma/st_druzba/1.jpg",
			"/assets/portfolio/doma/st_druzba/2.jpg",
			"/assets/portfolio/doma/st_druzba/3.jpg",
			"/assets/portfolio/doma/st_druzba/4.jpg",
			"/assets/portfolio/doma/st_druzba/5.jpg"
		]
	},
	{
		id: 5,
		title: "Частный дом по Гаппо Баева. Владикавказ, РСО-Алания. 2021",
		image: "/assets/portfolio/doma/gappo_baeva/1.jpg",
		tag: ["Последние проекты", "Частные дома"],
		images: [
			"/assets/portfolio/doma/gappo_baeva/1.jpg",
			"/assets/portfolio/doma/gappo_baeva/2.jpg",
			"/assets/portfolio/doma/gappo_baeva/3.jpg"
		]
	},
	{
		id: 6,
		title: "Частный дом по ул.Тургеневская. Владикавказ. РСО-Алания. 2020",
		image: "/assets/portfolio/doma/turgenevskaya/1.jpg",
		tag: ["Частные дома"],
		images: [
			"/assets/portfolio/doma/turgenevskaya/1.jpg",
			"/assets/portfolio/doma/turgenevskaya/2.jpg",
			"/assets/portfolio/doma/turgenevskaya/3.jpg"
		]
	},
	{
		id: 7,
		title: "Частный дом. Влдикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/doma/vladikavkaz/1.jpg",
		tag: ["Частные дома"],
		images: [
			"/assets/portfolio/doma/vladikavkaz/1.jpg",
			"/assets/portfolio/doma/vladikavkaz/2.jpg",
			"/assets/portfolio/doma/vladikavkaz/3.jpg",
			"/assets/portfolio/doma/vladikavkaz/4.jpg",
			"/assets/portfolio/doma/vladikavkaz/5.jpg"
		]
	},
	{
		id: 8,
		title: "Частный дом. Суадаг, РСО-Алания. 2020",
		image: "/assets/portfolio/doma/suadag/1.jpg",
		tag: ["Частные дома"],
		images: [
			"/assets/portfolio/doma/suadag/1.jpg",
			"/assets/portfolio/doma/suadag/2.jpg",
			"/assets/portfolio/doma/suadag/3.jpg",
			"/assets/portfolio/doma/suadag/4.jpg"
		]
	},
	{
		id: 9,
		title: "Частный дом. Фиагдон, РСО-Алания. 2021",
		image: "/assets/portfolio/doma/fiagdon/1.jpg",
		tag: ["Частные дома"],
		images: [
			"/assets/portfolio/doma/fiagdon/1.jpg",
			"/assets/portfolio/doma/fiagdon/2.jpg",
			"/assets/portfolio/doma/fiagdon/3.jpg",
			"/assets/portfolio/doma/fiagdon/4.jpg",
			"/assets/portfolio/doma/fiagdon/5.jpg",
			"/assets/portfolio/doma/fiagdon/6.jpg",
			"/assets/portfolio/doma/fiagdon/7.jpg",
			"/assets/portfolio/doma/fiagdon/8.jpg",
			"/assets/portfolio/doma/fiagdon/9.jpg"
		]
	},
]

export default Doma;