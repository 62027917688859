import React, { useState } from 'react'
import LazyImage from './LazyImage';

const Cards = ({ imgUrl, title, images }) => {

	const [sliderOpen, setSliderOpen] = useState(false);
	const [imageList, setImageList] = useState([]);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const openSlider = (imageUrls) => {
		if (title === "Предложение. Велогостиница Солянка. Владикавказ, РСО-Алания. 2023") {
			window.open("/assets/portfolio/fpt/Велогостиница Солянка.pdf", "_blank");
			setSliderOpen(false);
		} else {
			setImageList(imageUrls);
			setSliderOpen(true);
		}
	};

	const closeSlider = () => {
		setSliderOpen(false);
		setImageList([]);
		setSelectedImageIndex(0);
	};

	const prevImage = () => {
		setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? imageList.length - 1 : prevIndex - 1));
	};

	const nextImage = () => {
		setSelectedImageIndex((prevIndex) => (prevIndex === imageList.length - 1 ? 0 : prevIndex + 1));
	};

	return (
		<div>
			<div className="pt-5">
				<button onClick={() => openSlider(images)} className="w-full h-[340px] pb-[1.5625rem] no-underline hover:underline decoration-orange-300 underline-offset-4 decoration-4 cursor-pointer">
					<img className="w-full h-[calc(100%-40px)] object-cover hover:grayscale hover:duration-200 sm:border-l border-white" src={imgUrl} alt=''/>
					<div className="py-[.5rem] sm:px-[1rem] relative">
						<h1 className="text-[1rem] py-[.125rem] text-[#23262d]">{title}</h1>
					</div>
				</button>
			</div>
			{sliderOpen && (
				<div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 z-50 flex justify-center items-center">
					<button className="absolute z-50 top-0 right-0 m-4 text-white" onClick={closeSlider}>
							Закрыть
					</button>
					<div className="relative">
						<button className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={prevImage}>
							<span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
									<svg className="w-10 h-10 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
											<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M5 1 1 5l4 4"/>
									</svg>
							</span>
						</button>
						<button className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={nextImage}>
							<span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
									<svg className="w-10 h-10 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
											<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="m1 9 4-4-4-4"/>
									</svg>
							</span>
						</button>
						{/* <img src={imageList[selectedImageIndex]} alt="Slide" className="max-w-full max-h-full" /> */}
						<LazyImage src={imageList[selectedImageIndex]} alt="Slide" className="max-w-full max-h-full" />
					</div>
				</div>
			)}
		</div>
	);
}

export default Cards