import React from 'react';

const HeroSection = () => {
  return (
    <section className="bg-gray-200">
      <div className="w-full h-full">
        <header className="absolute z-10 font-semibold leading-none px-6 py-[12.5px] text-white text-[1.7578125rem] max-w-[9ch]">
          <a href="/" rel='home'>Студия Архитектуры <span>и&nbsp;</span>Дизайна Тамары Цогоевой</a>
        </header>
        <div className="w-full h-full block">
          <video 
            className="object-cover mb-[2.44140625rem]" 
            src="/assets/video.mp4" 
            loop 
            autoPlay 
            muted 
            playsInline
          ></video>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
