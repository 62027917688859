const Zdaniya = [
	{
		id: 1,
		title: "Автосалон на Архонском шоссе. Владикавказ, РСО-Алания. 2023",
		image: "/assets/portfolio/zdaniya/avtosalon/1.jpg",
		tag: ["Последние проекты","Общественные здания"],
		images: [
			"/assets/portfolio/zdaniya/avtosalon/1.jpg",
			"/assets/portfolio/zdaniya/avtosalon/2.jpg",
			"/assets/portfolio/zdaniya/avtosalon/3.jpg",
			"/assets/portfolio/zdaniya/avtosalon/4.jpg",
			"/assets/portfolio/zdaniya/avtosalon/5.jpg",
			"/assets/portfolio/zdaniya/avtosalon/6.jpg",
			"/assets/portfolio/zdaniya/avtosalon/7.jpg",
			"/assets/portfolio/zdaniya/avtosalon/8.jpg"
		]
	},
	{
		id: 2,
		title: "Гостиничный и жилой дом. Цей, РСО-Алания. 2023",
		image: "/assets/portfolio/zdaniya/gost_c/1.jpg",
		tag: ["Последние проекты", "Общественные здания"],
		images: [
			"/assets/portfolio/zdaniya/gost_c/1.jpg",
			"/assets/portfolio/zdaniya/gost_c/2.jpg",
			"/assets/portfolio/zdaniya/gost_c/2.1.jpg",
			"/assets/portfolio/zdaniya/gost_c/3.jpg",
			"/assets/portfolio/zdaniya/gost_c/4.jpg",
			"/assets/portfolio/zdaniya/gost_c/5.jpg",
			"/assets/portfolio/zdaniya/gost_c/5.1.jpg",
			"/assets/portfolio/zdaniya/gost_c/6.jpg"
		]
	},
	{
		id: 3,
		title: "Многофункциональный центр. Златоуст, Челябинская обл., РФ. 2021",
		image: "/assets/portfolio/zdaniya/zlatoust/1.jpg",
		tag: ["Последние проекты", "Общественные здания"],
		images: [
			"/assets/portfolio/zdaniya/zlatoust/1.jpg",
			"/assets/portfolio/zdaniya/zlatoust/2.jpg",
			"/assets/portfolio/zdaniya/zlatoust/3.jpg",
			"/assets/portfolio/zdaniya/zlatoust/4.jpg",
			"/assets/portfolio/zdaniya/zlatoust/5.jpg",
			"/assets/portfolio/zdaniya/zlatoust/6.jpg",
			"/assets/portfolio/zdaniya/zlatoust/7.jpg",
			"/assets/portfolio/zdaniya/zlatoust/8.jpg",
			"/assets/portfolio/zdaniya/zlatoust/9.jpg",
			"/assets/portfolio/zdaniya/zlatoust/10.jpg"
		]
	},
	{
		id: 4,
		title: "Центр национальных традиций и осетинского языка. Заманкул,РСО-Алания. 2024",
		image: "/assets/portfolio/zdaniya/centr_nac/1.jpg",
		tag: ["Последние проекты", "Общественные здания"],
		images: [
			"/assets/portfolio/zdaniya/centr_nac/1.jpg",
			"/assets/portfolio/zdaniya/centr_nac/2.jpg",
			"/assets/portfolio/zdaniya/centr_nac/3.jpg",
			"/assets/portfolio/zdaniya/centr_nac/4.jpg",
			"/assets/portfolio/zdaniya/centr_nac/5.jpg",
			"/assets/portfolio/zdaniya/centr_nac/6.jpg",
			"/assets/portfolio/zdaniya/centr_nac/7.jpg",
			"/assets/portfolio/zdaniya/centr_nac/8.jpg",
			"/assets/portfolio/zdaniya/centr_nac/9.jpg",
			"/assets/portfolio/zdaniya/centr_nac/10.jpg",
			"/assets/portfolio/zdaniya/centr_nac/11.jpg",
			"/assets/portfolio/zdaniya/centr_nac/12.jpg"
		]
	},
	{
		id: 5,
		title: "Пивная Зорге Паб на Моских Пехотинцев. Владикавказ, РСО-Алания, РФ. 2018",
		image: "/assets/portfolio/zdaniya/pivnaya/1.jpg",
		tag: ["Общественные здания"],
		images: [
			"/assets/portfolio/zdaniya/pivnaya/1.jpg",
			"/assets/portfolio/zdaniya/pivnaya/2.jpg",
			"/assets/portfolio/zdaniya/pivnaya/3.jpg"
		]
	},
	{
		id: 6,
		title: "Автосервис на Весенней. Владикавказ, РСО-Алания. 2022",
		image: "/assets/portfolio/zdaniya/avtoservice/1.jpg",
		tag: ["Общественные здания"],
		images: [
			"/assets/portfolio/zdaniya/avtoservice/1.jpg",
			"/assets/portfolio/zdaniya/avtoservice/2.jpg",
			"/assets/portfolio/zdaniya/avtoservice/3.jpg",
			"/assets/portfolio/zdaniya/avtoservice/4.jpg"
		]
	},
	{
		id: 7,
		title: "Кофейный киоск. Тбилиси, Грузия. 2018",
		image: "/assets/portfolio/zdaniya/coffe/1.jpg",
		tag: ["Общественные здания"],
		images: [
			"/assets/portfolio/zdaniya/coffe/1.jpg",
			"/assets/portfolio/zdaniya/coffe/2.jpg",
			"/assets/portfolio/zdaniya/coffe/3.jpg",
			"/assets/portfolio/zdaniya/coffe/4.jpg"
		]
	},
]

export default Zdaniya;