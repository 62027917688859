import React, { useRef, useState, useEffect } from 'react';

const LazyImage = ({ src, alt }) => {

	const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(imgRef.current);
    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

	return (
		<img
      ref={imgRef}
      src={isVisible ? src : ''}
      alt={alt}
    />
	)
}

export default LazyImage