const Landsh = [
	{
		id: 1,
		title: "Автосалон на Архонском шоссе. Владикавказ, РСО-Алания. 2023",
		image: "/assets/portfolio/landshaft/avt_arch/1.jpg",
		tag: ["Ландшафный дизайн"],
		images: [
			"/assets/portfolio/landshaft/avt_arch/1.jpg",
			"/assets/portfolio/landshaft/avt_arch/2.jpg",
			"/assets/portfolio/landshaft/avt_arch/3.jpg",
			"/assets/portfolio/landshaft/avt_arch/4.jpg",
			"/assets/portfolio/landshaft/avt_arch/5.jpg",
			"/assets/portfolio/landshaft/avt_arch/6.jpg",
			"/assets/portfolio/landshaft/avt_arch/7.jpg",
			"/assets/portfolio/landshaft/avt_arch/8.jpg"
		]
	},
	{
		id: 2,
		title: "Гостиничный дом и жилой дом. Цей, РСО-Алания. 2023",
		image: "/assets/portfolio/landshaft/gost_dom/1.jpg",
		tag: ["Ландшафный дизайн"],
		images: [
			"/assets/portfolio/landshaft/gost_dom/1.jpg",
			"/assets/portfolio/landshaft/gost_dom/2.jpg",
			"/assets/portfolio/landshaft/gost_dom/3.jpg",
			"/assets/portfolio/landshaft/gost_dom/4.jpg",
			"/assets/portfolio/landshaft/gost_dom/5.jpg",
			"/assets/portfolio/landshaft/gost_dom/6.jpg"
		]
	},
	{
		id: 3,
		title: "Центр национальных традиций и осетинского языка. Заманкул,РСО-Алания. 2024",
		image: "/assets/portfolio/landshaft/centr_nac/1.jpg",
		tag: ["Ландшафный дизайн"],
		images: [
			"/assets/portfolio/landshaft/centr_nac/1.jpg",
			"/assets/portfolio/landshaft/centr_nac/2.jpg",
			"/assets/portfolio/landshaft/centr_nac/3.jpg",
			"/assets/portfolio/landshaft/centr_nac/4.jpg",
			"/assets/portfolio/landshaft/centr_nac/5.jpg",
			"/assets/portfolio/landshaft/centr_nac/6.jpg",
			"/assets/portfolio/landshaft/centr_nac/7.jpg",
			"/assets/portfolio/landshaft/centr_nac/8.jpg",
			"/assets/portfolio/landshaft/centr_nac/9.jpg",
			"/assets/portfolio/landshaft/centr_nac/10.jpg",
			"/assets/portfolio/landshaft/centr_nac/11.jpg",
			"/assets/portfolio/landshaft/centr_nac/12.jpg"
		]
	},
	{
		id: 4,
		title: "Частный дом в СНО Терек. Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/landshaft/ch_dom_sno/1.jpg",
		tag: ["Ландшафный дизайн"],
		images: [
			"/assets/portfolio/landshaft/ch_dom_sno/1.jpg",
			"/assets/portfolio/landshaft/ch_dom_sno/2.jpg",
			"/assets/portfolio/landshaft/ch_dom_sno/3.jpg",
			"/assets/portfolio/landshaft/ch_dom_sno/4.jpg",
			"/assets/portfolio/landshaft/ch_dom_sno/5.jpg"
		]
	},
	{
		id: 5,
		title: "Частный дом в СТ Дружба. Владикавказ, РСО-Алания. 2021",
		image: "/assets/portfolio/landshaft/ch_dom_st/1.jpg",
		tag: ["Ландшафный дизайн"],
		images: [
			"/assets/portfolio/landshaft/ch_dom_st/1.jpg",
			"/assets/portfolio/landshaft/ch_dom_st/2.jpg",
			"/assets/portfolio/landshaft/ch_dom_st/3.jpg",
			"/assets/portfolio/landshaft/ch_dom_st/4.jpg",
			"/assets/portfolio/landshaft/ch_dom_st/5.jpg"
		]
	},
	{
		id: 6,
		title: "Частный дом. Фиагдон, РСО-Алания. 2021",
		image: "/assets/portfolio/landshaft/ch_dom_fiag/1.jpg",
		tag: ["Ландшафный дизайн"],
		images: [
			"/assets/portfolio/landshaft/ch_dom_fiag/1.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/2.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/3.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/4.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/5.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/6.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/7.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/8.jpg",
			"/assets/portfolio/landshaft/ch_dom_fiag/9.jpg",
		]
	},
]

export default Landsh;