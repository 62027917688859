import React, { useState } from 'react';

const CommentsSetion = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containers = [
    { imgSrc: '/assets/portfolio/doma/turgenevskaya/1.jpg', title: 'Нас зовут Ацамаз и Наташа', text: 'Для нас архитектурный проект с дизайном делала Тамара, очень грамотный специалист своего дела. Понравилось все,учла все наши требования и вкусы,она очень тактичный, тонко чувствующий заказчика профессионал! Всегда советует, как будет лучше и удобней! всегда советует интересные решения, помогает с выбором материалов. В итоге мы очень довольны нашим домом, всем приходящим нравится, у нас получился дом нашей мечты!'},
    { imgSrc: '/assets/portfolio/interer/1-aya_kv_mosk_50м²/1.jpg', title: 'Меня зовут Ирина', text: 'Работать с дизайнером Тамарой было одно удовольствие. Тамара учитывала все мои пожелания, предложила несколько проектов и дизайнов. Я осталась очень довольна грамотным подходом к важным деталям, а так же к мелочам. Уложились в срок. Я осталась очень довольна! Спасибо Томочка за профессионализм Рекомендую.' },
    { imgSrc: '/assets/portfolio/zdaniya/avtosalon/1.jpg', title: 'Меня зовут Владимир', text: 'Для нас делала архитектурный проект Тамара. Образ здания нам понравился. Понравились все этапы сотрудничества. Тамара со вкусом и с учетом всех наших пожеланий спроектировала здание, которое, я думаю, будет украшать наш город. Проект был выполнен технически грамотно.Наши пожелания все были учтены. Мой отзыв: Рекомендую архитектора Тамару,если кому-то нужен качественный , современный, стильный проект зданий и помещений.' },
    { imgSrc: '/assets/portfolio/1.jpg', title: 'Нас зовут Алан и Марина', text: 'Тамара нам делала дизайн проект на двух объектах - два частных дома. Сказать, что нам понравилось - ничего не сказать! Мы были просто в восторге! По первому дому был полный проект. От электропроводки до подбора и расстановки мебели. Были учтены не только наши пожелания, но и предложены дизайн-решения, которые превзошли все наши ожидания. Каждая деталь продумана до мелочей. В доме гармония и свой уникальный стиль. Во втором объекте была перепланировка, где Тамара помогла нам в решении непростой задачи, проявив профессионализм и творчекий подход, благодаря чему дом обрёл новую жизнь, стал более уютным и функциональным. Тамара, спасибо Вам за Ваш талант! Однозначно рекомендуем дизайнера, профессионала своего дела и просто хорошего человека - Тамару, тем, кто хочет жить в комфортном, уютном, функциональном, умном доме!' },
    { imgSrc: '/assets/portfolio/interer/1-aya_kv_mosk_121/1.jpg', title: 'Нас зовут Андрей и Аида', text: 'Для нас делала дизайн квартиры Тамара. Нам понравилось: Понравилось все, начиная с начала проекта и заканчивая его реализацией. Тамара подобрала все со вкусом и с учетом наших пожеланий. Перепланировка была выполнена технически правильно, вопросов у прораба и его строительной компании не возникало. Дизайн получился современным, с учетом новых иехнологий , а главное реализуемым. Наши замечания, если и были, все было учтено и скорректировано. Работать с дизайнером Тамарой было очень приятно. Мой отзыв: Рекомендую архитектора Тамару,если кому-то нужен качественный , современный, стильный дизайн для квартиры, для дома.' },
    { imgSrc: 'https://via.placeholder.com/150', title: 'Меня зовут Артур', text: 'Для меня проектировала дом Тамара. Внимание к моим пожеланиям, финальный результат. Мой отзыв: Отличный архитектор, с удовольствием обратился бы к ней снова!' },
    { imgSrc: '/assets/portfolio/doma/st_druzba/2.jpg', title: 'Меня зовут Светлана', text: 'Дом нам проектировала Тамара. Мы в восторге от результата. Дом получился очень теплым и уютным! Хотим еще раз обратиться за дизайном и внутри дома.' },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % containers.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + containers.length) % containers.length);
  };

  return (
    <div className="flex items-center justify-center h-[300px] md:h-[320px] my-12 md:my-6 lg:my-8">
      <button onClick={prevSlide} className="py-2 px-3 ml-3 bg-gray-300 text-white rounded-full">
        &larr;
      </button>
      <div className="mt-6 mx-4 flex items-center justify-center w-full h-full max-w-[300px] max-h-[350px] lg:max-w-[90%] lg:max-h-[420px] md:max-w-[420px] md:max-h-[250px]">
        <div className="flex flex-col lg:flex-row w-full h-full">
          <div className="lg:w-1/3 w-full flex items-center justify-center">
            <img src={containers[currentIndex].imgSrc} alt="carousel" className="lg:h-1/3 lg:w-[60%] h-[60px] w-[130px]" />
          </div>
          <div className="lg:w-2/3 w-full pt-2 flex flex-col justify-center overflow-hidden">
            <h1 className="text-[15px] sm:text-xl font-bold text-center lg:text-start">
              {containers[currentIndex].title}
            </h1>
            <p className="text-[13px] md:w-[calc(100%-80px)] sm:text-lg text-center lg:text-start overflow-y-auto" style={{ maxHeight: '10em', lineHeight: '1.4em' }}>
              {containers[currentIndex].text}
            </p>
          </div>
        </div>
      </div>
      <button onClick={nextSlide} className="py-2 px-3 mr-3 bg-gray-300 text-white rounded-full">
        &rarr;
      </button>
    </div>
  );
};

export default CommentsSetion;