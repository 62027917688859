import React from 'react'

const CardsTag = ({ name, onClick, isSelected }) => {
	const buttonStyles = isSelected
			? "text-black underline decoration-orange-300 underline-offset-4 decoration-4"
			: "text-black hover:underline decoration-orange-300 underline-offset-4 decoration-4";
	return (
			<button
			className={`${buttonStyles}  lg:bg-gray-200 bg-gray-300 px-3 py-2 lg:m-[0px] m-[2px] text-sm cursor-pointer`}
			onClick={() => onClick(name)}
			>
				{name}
			</button>
	);
}

export default CardsTag