const Inter = [
	{
		id: 1,
		title: "Вариант интерьера 1-комнатной квартиры по Московской. 50 м² Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/interer/1-aya_kv_mosk_50/1.jpg",
		tag: ["Последние проекты", "Интерьеры"],
		images: [
			"/assets/portfolio/interer/1-aya_kv_mosk_50/1.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/2.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/3.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/4.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/5.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/6.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/7.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/8.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50/9.jpg",
		]
	},
	{
		id: 2,
		title: "Вариант интерьера 1-комнатной квартиры по Московской. 50 м² Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/interer/1-aya_kv_mosk_50м²/1.jpg",
		tag: ["Последние проекты", "Интерьеры"],
		images: [
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/1.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/2.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/3.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/4.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/5.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/6.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/7.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/8.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/9.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/10.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/11.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_50м²/12.jpg",
		]
	},
	{
		id: 3,
		title: "Вариант интерьера квартиры по Московскому ш. 121 м² Владикавказ, РСО-Алания. 2019",
		image: "/assets/portfolio/interer/1-aya_kv_mosk_121/1.jpg",
		tag: ["Интерьеры"],
		images: [
			"/assets/portfolio/interer/1-aya_kv_mosk_121/1.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/2.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/3.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/4.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/5.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/6.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/7.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/8.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/9.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121/10.jpg",
		]
	},
	{
		id: 4,
		title: "Вариант интерьера квартиры по Московскому ш. 121 м² Владикавказ, РСО-Алания. 2019",
		image: "/assets/portfolio/interer/1-aya_kv_mosk_121м²/1.jpg",
		tag: ["Интерьеры"],
		images: [
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/1.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/2.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/3.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/4.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/5.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/6.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/7.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/8.jpg",
			"/assets/portfolio/interer/1-aya_kv_mosk_121м²/9.jpg",
		]
	},
	{
		id: 5,
		title: "Вариант интерьера столовой в частном доме.  34 м² Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/interer/st-ya_34м/1.jpg",
		tag: ["Интерьеры"],
		images: [
			"/assets/portfolio/interer/st-ya_34м/1.jpg",
			"/assets/portfolio/interer/st-ya_34м/2.jpg",
			"/assets/portfolio/interer/st-ya_34м/3.jpg",
			"/assets/portfolio/interer/st-ya_34м/4.jpg",
		]
	},
	{
		id: 6,
		title: "Вариант интерьера столовой в частном доме.  34 м² Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/interer/st-ya_34м²/1.jpg",
		tag: ["Интерьеры"],
		images: [
			"/assets/portfolio/interer/st-ya_34м²/1.jpg",
			"/assets/portfolio/interer/st-ya_34м²/2.jpg",
			"/assets/portfolio/interer/st-ya_34м²/3.jpg",
			"/assets/portfolio/interer/st-ya_34м²/4.jpg",
		]
	},
	{
		id: 7,
		title: "Интерьер квартиры для сдачи в аренду. 120 м² Владикавказ, РСО-Алания. 2020",
		image: "/assets/portfolio/interer/inter_kv-ra/4.jpg",
		tag: ["Интерьеры"],
		images: [
			"/assets/portfolio/interer/inter_kv-ra/1.jpg",
			"/assets/portfolio/interer/inter_kv-ra/2.jpg",
			"/assets/portfolio/interer/inter_kv-ra/3.jpg",
			"/assets/portfolio/interer/inter_kv-ra/4.jpg",
			"/assets/portfolio/interer/inter_kv-ra/5.jpg",
			"/assets/portfolio/interer/inter_kv-ra/6.jpg",
			"/assets/portfolio/interer/inter_kv-ra/7.jpg",
			"/assets/portfolio/interer/inter_kv-ra/8.jpg"
		]
	},
	{
		id: 8,
		title: "Интерьер на Первомайской. 102 м² Владикавказ, РСО-Алания. 2018",
		image: "/assets/portfolio/interer/inter_kv-ra_102/1.jpg",
		tag: ["Интерьеры"],
		images: [
			"/assets/portfolio/interer/inter_kv-ra_102/1.jpg",
			"/assets/portfolio/interer/inter_kv-ra_102/2.jpg",
			"/assets/portfolio/interer/inter_kv-ra_102/3.jpg",
			"/assets/portfolio/interer/inter_kv-ra_102/4.jpg",
			"/assets/portfolio/interer/inter_kv-ra_102/5.jpg",
			"/assets/portfolio/interer/inter_kv-ra_102/6.jpg",
			"/assets/portfolio/interer/inter_kv-ra_102/7.jpg",
			"/assets/portfolio/interer/inter_kv-ra_102/8.jpg"
		]
	},
]

export default Inter;