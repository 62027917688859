// import CardsSection from './components/cards/CardsSection'
import CardsSection from './components/cards/CardsSection'
import CommentsSetion from './components/comments/CommentsSetion'
import ContactSection from './components/contact/ContactSection'
import HeroSection from './components/hero/HeroSection'

function App() {
  return (
    <main className="flex items-center justify-center">
      <div className="lg:mx-6 xl:mx-20 h-full bg-gray-200">
				<HeroSection/>
				<CardsSection/>
				<CommentsSetion/>
				<ContactSection/>
      </div>
    </main>
  );
}

export default App;
