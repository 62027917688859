"use client";
import React, { useState, useRef } from "react";
import CardsTag from './components/CardsTag'
import Cards from './components/Cards'
import Inter from '../../data/inter';
import Landsh from '../../data/landsh';
import Doma from '../../data/doma'
import Zdaniya from '../../data/zdaniya'
import Fpt from '../../data/fpt'


const CardsSection = () => {

	const projectsData = [
		...Doma,
		...Zdaniya,
		...Inter,
		...Landsh,
		...Fpt
	]

	const [tag, setTag] = useState("Последние проекты");
    const ref = useRef(null);

    const handleTagChange = (newTag) => {
        setTag(newTag);
    };

    const filteredProjects = projectsData.filter((project) =>
        project.tag.includes(tag)
    );

	return (
		<section>
			<div className="pb-[0.8rem]">
				<h1 className="flex px-[25px] text-[22px] sm:text-[25px] pb-5 leading-tight font-normal">Проекты</h1>
			</div>
			<div className='text-white flex flex-row justify-start items-center flex-wrap p-3'>
				<CardsTag
				onClick={handleTagChange}
				name="Последние проекты"
				isSelected={tag === "Последние проекты"}
				/>
				<CardsTag
				onClick={handleTagChange}
				name="Частные дома"
				isSelected={tag === "Частные дома"}
				/>
				<CardsTag
				onClick={handleTagChange}
				name="Общественные здания"
				isSelected={tag === "Общественные здания"}
				/>
				<CardsTag
				onClick={handleTagChange}
				name="Интерьеры"
				isSelected={tag === "Интерьеры"}
				/>
				<CardsTag
				onClick={handleTagChange}
				name="Ландшафный дизайн"
				isSelected={tag === "Ландшафный дизайн"}
				/>
				<CardsTag
					onClick={handleTagChange}
					name="Функциональное планирование территории"
					isSelected={tag === "Функциональное планирование территории"}
				/>
			</div>
			<div ref={ref} className="grid grid-cols-1 px-[12.8px] sm:grid-cols-2 sm:p-0 md:grid-cols-3 w-full">
				{filteredProjects.map((project, index) => (
					<div
					key={index}
					>
						<Cards
						key={project.id}
						title={project.title}
						imgUrl={project.image}
						images={project.images}
						/>
					</div>
				))}
			</div>
		</section>
	)
}

export default CardsSection
